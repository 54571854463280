define('jThumbsM', ['app', 'siteObj', '$console'], (app, siteObj, $console) => {
  
  /** jThumbsM */
  class jThumbsM {
    constructor() {
      this.jZoomOverlay = document.querySelector('.jZoom_overlay');
      this.jZoomImage = document.querySelector('[data-j-zoom-main="true"]');
      this.openLink = document.querySelector('.jZoom_button-open');
      this.openIcon = document.querySelector('.jZoom_open-icon');
      this.setNewImage = this.setNewImage.bind(this);
      const rsTrue = document.querySelector('.royal-slider');

      this.getActiveSlide()
        .then((val) => {
          return this.switchImage(val);
        })
        .then((val) => {
          this.resolvedImage(val);
        })
        .catch((err) => {
          this.rejectedImage(err);
        });

      if (rsTrue) {
        rsTrue.addEventListener('touchend', () => {
          this.setNewImage()
            .then((val) => {
              return this.switchImage(val);
            })
            .then((val) => {
              app.element.removeClass('jZoom_button-superhide', this.openLink);
              app.element.removeClass('jZoom-superhide', this.openIcon);
              app.element.removeClass('jZoom_overlay-superhide', document.querySelector('.jZoom_overlay'));

              this.resolvedImage(val);
            })
            .catch((err) => {
              this.rejectedImage(err);
            });
        });
      }
    }

    /**
     * Ensure jZoom is visible.
     * @param val
     */
    resolvedImage(val) {

      if (!this.jZoomImage) {
        this.jZoomImage = document.querySelector('[data-j-zoom-main="true"]');
      }

      this.jZoomImage.src = val;
      app.element.removeClass('jZoom_button-superhide', this.openLink);
      app.element.removeClass('jZoom-superhide', this.openIcon);
      app.element.removeClass('jZoom_overlay-superhide', document.querySelector('.jZoom_overlay')); // todo - hide open buttons
    }

    /**
     * Ensure jZoom is hidden.
     * @param err
     */
    rejectedImage(err) {
      app.element.addClass('jZoom_button-superhide', this.openLink);
      app.element.addClass('jZoom-superhide', this.openIcon);
      app.element.addClass('jZoom_overlay-superhide', document.querySelector('.jZoom_overlay')); // todo - hide open buttons
      $console.warn(`jZoom: Error: ${err}`);
    }

    /**
     * Switch the main image.
     * @param val
     * @returns {*}
     */
    switchImage(val) {
      return this.testInitialImage(val);
    }

    /**
     * TODO - this will be implemented in v2
     * TODO - in v2 this will hit an Elysium endpoint to check if the image exists rather than querying the CDN directly
     * See if an image has a 1600x1600 variant.
     * @param CDN
     * @param link
     * @returns {Promise}
     */
    testImage(CDN, link) {
      const resize = link.replace('300/300', '1600/1600');
      const imageLink = `http://s${CDN}.thcdn.com/productimg${resize}`;
      const xhr = new XMLHttpRequest();

      xhr.open('GET', encodeURI(imageLink));

      return new Promise((resolve, reject) => {
        xhr.onload = () => {
          const res = xhr.response;

          if (!res || xhr.status !== 200) {
            return reject('jZoom: Hidden: Could not find large image variant.');
          }

          return resolve(imageLink);
        };

        xhr.send();
      });
    }

    /**
     * See if an image has a 1600x1600 image variant without querying the CDN
     * @param thumbnail
     * @returns {Promise}
     */
    testInitialImage(thumbnail) {
      const thumbnailContainer = thumbnail.querySelector('img');
      const alt = thumbnailContainer.alt.split('');
      const imageIndex = parseInt(alt[alt.length - 1]) - 1;
      const imageLinks = document.querySelector('.jZoom_imageLinks .jZoom_magnify');

      return new Promise((resolve, reject) => {
        const imageEl = imageLinks.querySelector(`[data-j-zoom-image-link-${imageIndex}]`);
        const link = imageEl.getAttribute('data-image-link');

        if (link) {
          return resolve(`${siteObj.productImagePrefix}${link}`);
        } else {
          reject('jZoom: Hidden: Could not find large image variant.');
        }
      });
    }

    /**
     * Get a random number between 1 and 4.
     * @returns {number}
     */
    getRandom() {
      const min = 1;
      const max = 5;

      return Math.floor(Math.random() * (max - min) + min);
    }

    /**
     * Wait for Royal Slider to finish changing image and return new image.
     * @returns {Promise}
     */
    setNewImage() {
      const lastSlide = document.querySelector('.product-image-slider .rsContainer .rsActiveSlide');

      return new Promise((resolve, reject) => {
        let i = 0;

        const waitForNewSlide = () => {
          const newSlide = document.querySelector('.product-image-slider .rsContainer .rsActiveSlide');

          if (newSlide !== lastSlide) {
            return resolve(newSlide);
          } else if (newSlide === lastSlide) {
            setTimeout(waitForNewSlide, 0);
          } else if (i > 19) {
            return reject('jZoom: Error: Could not find new image.');
          }

          i++;
        };
        waitForNewSlide();
      });
    }

    /**
     * Find the currently active Royal Slider slide.
     * @returns {Promise}
     */
    getActiveSlide() {
      return new Promise((resolve, reject) => {
        const singleImage = document.querySelector('[data-j-zoom="single-image"]');
        let i = 0;

        if (singleImage) {
          return resolve(singleImage);
        }

        const waitForRoyalSlider = () => {
          const rs = document.querySelector('.product-image-slider .rsContainer .rsActiveSlide');

          if (!rs) {
            setTimeout(waitForRoyalSlider, 0);
          } else if (i > 19) {
            return reject('jZoom: Error: Could not find Royal Slider.');
          } else if (rs) {
            return resolve(rs);
          }

          i++;
        };

        waitForRoyalSlider();
      });
    }
  }

  return jThumbsM;
});
